import store from '@state/store.js'
import { lazyLoadView } from '@router/routes.js'

export default [
  {
    path: '/manage',
    component: () => lazyLoadView(import('@views/main.vue')),
    name: 'base',
    children: [
      {
        path: '/manage',
        name: 'home',
        component: () => lazyLoadView(import('@views/dashboard/dashboard.vue')),
        meta: {
          authRequired: true,
          section: 'dashboard',
          action: 'read',
        },
      },

      // bollato
      {
        path: '/manage/bollato_movimenti',
        name: 'bollato_movimenti_create',
        component: () =>
          lazyLoadView(import('@views/bollato_movimenti/create.vue')),
        meta: {
          authRequired: true,
          section: 'bollato',
          action: 'create',
        },
      },
      // bollati
      {
        path: '/manage/bollati',
        name: 'bollati_search',
        component: () => lazyLoadView(import('@views/bollati/search.vue')),
        meta: {
          authRequired: true,
          section: 'bollati',
          action: 'search',
        },
      },
      // download
      {
        path: '/manage/download',
        name: 'download',
        component: () => lazyLoadView(import('@views/download/search.vue')),
        meta: {
          authRequired: true,
          section: 'uploaded_files_progress',
          action: 'menu',
        },
      },
      // customers
      {
        path: '/manage/customers',
        name: 'customers_search',
        component: () => lazyLoadView(import('@views/customers/search.vue')),
        meta: {
          authRequired: true,
          section: 'customers',
          action: 'search',
        },
      },
      {
        path: '/manage/customers/create',
        name: 'customers_create',
        component: () => lazyLoadView(import('@views/customers/create.vue')),
        meta: {
          authRequired: true,
          section: 'customers',
          action: 'create',
        },
      },
      {
        path: '/manage/customers/:id',
        name: 'customers_detail',
        component: () => lazyLoadView(import('@views/customers/details.vue')),
        meta: {
          authRequired: true,
          section: 'customers',
          action: 'read',
        },
      },

      // tickets
      {
        path: '/manage/tickets',
        name: 'tickets_search',
        component: () => lazyLoadView(import('@views/tickets/search.vue')),
        meta: {
          authRequired: true,
          section: 'tickets',
          action: 'search',
        },
      },
      {
        path: '/manage/tickets/:id',
        name: 'tickets_detail',
        component: () => import('@views/tickets/details.vue'),
        meta: {
          authRequired: true,
          section: 'tickets',
          action: 'read',
        },
      },
      // {
      //   path: '/manage/tickets/create',
      //   name: 'tickets_create',
      //   component: () => lazyLoadView(import('@views/tickets/create.vue')),
      //   meta: {
      //     authRequired: true,
      //     section: 'tickets',
      //     action: 'create',
      //     denyAction: 'create_custom',
      //   },
      // },
      //
      // gestione ticket properties
      {
        path: '/manage/settings/tickets_activities',
        name: 'ticket_activities_search',
        component: () =>
          import('@views/settings/tickets_activities/search.vue'),
        meta: {
          authRequired: true,
          section: 'tickets_activities',
          action: 'create',
        },
      },
      {
        path: '/manage/settings/tickets_statuses',
        name: 'ticket_statuses_search',
        component: () => import('@views/settings/tickets_statuses/search.vue'),
        meta: {
          authRequired: true,
          section: 'tickets_statuses',
          action: 'create',
        },
      },
      {
        path: '/manage/settings/tickets_resolutions',
        name: 'tickets_resolutions_search',
        component: () =>
          import('@views/settings/tickets_resolutions/search.vue'),
        meta: {
          authRequired: true,
          section: 'tickets_resolutions',
          action: 'create',
        },
      },
      // {
      //   path: '/manage/settings/importer',
      //   name: 'uploaded_files_progress_search',
      //   component: () => import('@views/settings/uploads/search.vue'),
      //   meta: {
      //     authRequired: true,
      //     section: 'uploaded_files',
      //     action: 'create',
      //   },
      // },
      // {
      //   path: '/manage/settings/importer/:id',
      //   name: 'uploaded_files_error_details',
      //   component: () => import('@views/settings/uploads/details.vue'),
      //   meta: {
      //     authRequired: true,
      //     section: 'uploaded_files',
      //     action: 'read',
      //   },
      // },
      // {
      //   path: '/manage/nav_entries',
      //   name: 'nav_entries',
      //   component: () => lazyLoadView(import('@views/nav_entries/search.vue')),
      //   meta: {
      //     authRequired: true,
      //     section: 'nav_entries',
      //     action: 'create',
      //   },
      // },
      // {
      //   path: '/manage/settings',
      //   name: 'settings',
      //   component: () => lazyLoadView(import('@views/settings/index.vue')),
      //   meta: {
      //     authRequired: true,
      //     section: 'admin.settings',
      //     action: 'search',
      //   },
      // },

      // payments
      {
        path: '/manage/payments',
        name: 'payments_surplus',
        component: () => lazyLoadView(import('@views/payments/search.vue')),
        meta: {
          authRequired: true,
          section: 'tickets',
          action: 'search',
        },
      },

      // gestione users
      {
        path: '/manage/admin/users',
        name: 'admin.users',
        component: () => import('@views/admin/users/search.vue'),
        meta: {
          authRequired: true,
          section: 'admin.users',
          action: 'search',
        },
      },
      {
        path: '/manage/admin/users/create',
        name: 'users_create',
        component: () => lazyLoadView(import('@views/admin/users/create.vue')),
        meta: {
          authRequired: true,
          section: 'admin.users',
          action: 'create',
        },
      },
      {
        path: '/manage/admin/users/permissions',
        name: 'users_permissions',
        component: () =>
          lazyLoadView(import('@views/admin/users/permissions.vue')),
        meta: {
          authRequired: true,
          section: 'admin.permissions',
          action: 'menu',
        },
      },
      {
        path: '/manage/profile',
        name: 'profile',
        component: () => import('@views/profile.vue'),
        meta: {
          authRequired: true,
        },
        props: (route) => ({ user: store.state.auth.currentUser || {} }),
      },
      {
        path: '/manage/update_password',
        name: 'update_password',
        component: () =>
          lazyLoadView(import('@views/admin/users/updatePassword.vue')),
        meta: {
          authRequired: true,
        },
      },
      {
        path: '/manage/settings/importer/:id',
        name: 'uploaded_files_error_details',
        component: () => import('@views/settings/uploads/details.vue'),
        meta: {
          authRequired: true,
          section: 'uploaded_files',
          action: 'read',
        },
      },
      {
        path: '/manage/nav_entries',
        name: 'nav_entries',
        component: () => lazyLoadView(import('@views/nav_entries/search.vue')),
        meta: {
          authRequired: true,
          section: 'nav_entries',
          action: 'create',
        },
      },
      {
        path: '/manage/settings',
        name: 'settings',
        component: () => lazyLoadView(import('@views/settings/index.vue')),
        meta: {
          authRequired: true,
          section: 'admin.settings',
          action: 'search',
        },
      },
      ...(process.env.NODE_ENV === 'development'
        ? [
            {
              path: '/manage/dev',
              name: 'dev',
              component: () => lazyLoadView(import('@views/dev.vue')),
              meta: {},
            },
          ]
        : []),
    ],
  },
  {
    path: '/manage/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/login.vue')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: 'home' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/manage/recovery',
    name: 'recovery',
    component: () => lazyLoadView(import('@views/recovery.vue')),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/manage/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )

        console.log('authRequiredOnPreviousRoute ' + authRequiredOnPreviousRoute);
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom })
      },
    },
  },
]
