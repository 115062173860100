import { lazyLoadView } from '@router/routes.js'
import store from '@state/store'

export default [
  {
    path: '/',
    component: () => lazyLoadView(import('@views/public.vue')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        await store.dispatch('auth/getCsrfToken')
        return next()
      },
    },
    children: [
      {
        path: '/',
        name: 'public_home',
        redirect: { name: 'login' },
      },
      // {
      //   path: '/mypublicpage',
      //   name: 'public_page',
      //   component: () => lazyLoadView(import('@views/public/sample.vue')),
      //   meta: {
      //     authRequired: false,
      //   },
      // },
    ],
  },
]
