import moment from 'moment'
import storeFactory from '@state/template'
import { netRequest } from '@api/client.js'
import { GENERIC_ERROR_MESSAGE } from '@utils/constants';

const apiResourceName = 'tickets'
const modelName = 'lavorazione'

const model = {
  activity: {
    category: null,
    macro: null,
    subcategory: null,
  },
  activity_id: null,
  asset_id: null,
  channel: null,
  customer_id: null,
  customer_location_id: null,
  customer_notes: null,
  customer_user_id: null,
  customer_callback_num: null,
  slot_id: null,
  date_appointment: null,
  date_appointment_end: null,
  external_id: null,
  file: null,
  files: [],
  operator_notes: null,
  partner_ticket_id: null,
  partner_ticket_tracking: null,
  plans_subscription_id: null,
  received_at: null,
  resolution: 'Nuova',
  status: 'ACQUISITA',
  solicited_at: null,
  solicited_managed_at: null,
  prioritary: false,
  additional_fields: [],
  new_resolution: null,
  new_status: null,
  customer: {
    country: null,
  }
}

const filters = {
  anomaly: null,
  assigned_at: null,
  category: [],
  channel: [],
  closed_at: null,
  created_at: null,
  updated_at: null,
  customer_id: null,
  customer_user_id: null,
  date_appointment: null,
  external_id: null,
  kasko: null,
  macro: [],
  managed_at: null,
  managed_by: null,
  resolution: [],
  serial_imei: null,
  solicited_at: null,
  status: [],
  subcategory: [],
  tobe_closed_by: null,
  tobe_managed_by: null,
  type: [],
  additional_fields: [],
  country: [],
}

const state = {
  anomalies: [
    //     Aperte negli ultimi 15 giorni
    //     Aperte da più di 3 mesi
    //     Non recuperabili (senza filtri data)
    // Pagate nel mese precedente
    // Fatturate nel mese corrente

    { value: 'open_last_15', text: 'Aperte negli ultimi 15 giorni' },
    { value: 'open_more_than_3m', text: 'Aperte da più di 3 mesi' },
    { value: 'lost', text: 'Non recuperabili (senza filtri data)' },
    { value: 'pay_last_month', text: 'Pagate nel mese precedente' },
    { value: 'billed_current_month', text: 'Fatturate nel mese corrente' },
    {
      value: 'anomaly_no_manage',
      text: 'Posizioni non ancora presa in carico',
    },
    // { value: 'anomaly_sla_1', text: 'SLA1: Presa in carico' },
    // { value: 'anomaly_sla_2', text: 'SLA2: Chiusura posizione' },
    // { value: 'anomaly_solicit_not_taken', text: 'Sollecito ricevuto' },
    // { value: 'anomaly_solicit_taken', text: 'Sollecito gestito' },
  ],
  todayPending: [],
}

const dropdownScopes = []
const sortDropdownBy = []
const sortDropdownDesc = []

const getters = {
  // get if ticket is assigned to current user
  isAssignedToUser(state, getters, rootState, rootGetters) {
    return state.current.managed_by === rootGetters['auth/getCurrentUsername']
  },

  // get if ticket is assigned to current user
  canSolicitTicket(state, getters, rootState, rootGetters) {
    return (
      !state.current.closed_at &&
      (!state.current.prioritary ||
        (state.current.prioritary && state.current.solicited_managed_at))
    )
  },

  isTicketBlocked(state) {
    if (['RECALL', 'FRODE', 'SOSPESA'].includes(state.current.status)) {
      return state.current.resolution !== 'Non confermata [ebay]';
    }
    return false;
  },

  isPendingSolicit(state, getters, rootState, rootGetters) {
    return (
      state.current.prioritary &&
      state.current.solicited_at &&
      !state.current.solicited_managed_at
    )
  },

  getFormattedDateAppointment(state) {
    if (!state.current.date_appointment) return null
    return moment(state.current.date_appointment).format('DD/MM/YYYY HH:mm')
  },
  getFormattedDateAppointmentEnd(state) {
    if (!state.current.date_appointment_end) return null
    return moment(state.current.date_appointment_end).format('DD/MM/YYYY HH:mm')
  },
}

const mutations = {
  SET_PENDING(state, value) {
    state.todayPending = value
  },
  RESET_PENDING(state) {
    state.todayPending = []
  },
}

const actions = {
  async getCurrentRelatedData({ commit, state, rootState, dispatch }) {
    try {
      commit('SET_LOADING')

      const reqs = [
        // dettagli cliente
        dispatch('customers/setCurrent', state.current.customer_id, {
          root: true,
        }),

        // ticket activity
        dispatch('ticketsActivities/setCurrent', state.current.activity_id, {
          root: true,
        }),

        // dispatch di una action sulla
        // ...(state.current.asset_id
        //   ? [
        //       dispatch('customersAssets/setCurrent', state.current.asset_id, {
        //         root: true,
        //       }),
        //     ]
        //   : []),
      ]

      await Promise.all(reqs)

      // External Data
      dispatch('externalData/readData', null, {
        root: true,
      })

    } catch (err) {
    } finally {
      commit('RESET_LOADING')
    }
  },

  async getPendingTickets({ commit, state, rootState, dispatch }) {
    try {
      const today = new Date().toISOString().split('T')[0]
      const data = await netRequest('POST', `tickets/search`, {
        ctx: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['date_appointment'],
          sortDesc: [false],
        },
        filters: { date_appointment: [today, today] },
        scopes: ['customer', 'activity'],
      })

      commit('SET_PENDING', data.rows)
    } catch (err) {
      commit('SET_PENDING', [])
    }
  },

  async updateOperatorForTickets({ commit, dispatch }, params) {
    try {
      await netRequest('POST', `tickets/update_operator`, params);
    } catch (err) {
      dispatch('notifications/error', GENERIC_ERROR_MESSAGE, { root: true });
      console.warn('update error', err)
      throw err;
    }
  },

  async sendReminderNotification({ commit, dispatch }, params) {
    try {
      await netRequest('POST', `tickets/send_reminder_notification`, params);
      dispatch('notifications/success', 'Notifiche inviate correttamente', { root: true });
    } catch (err) {
      dispatch('notifications/error', GENERIC_ERROR_MESSAGE, { root: true });
      console.warn('update error', err)
      throw err;
    }
  }
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  mutations,
  filters,
  state,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
  getters,
  actions,
})
