import storeFactory from '@state/template'

const apiResourceName = 'ebay/scrub_reason'
const modelName = 'ScrubReason'

const model = {
  scrub_reason: null,
  scrub_rsn_id: null,
}

const filters = {
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
