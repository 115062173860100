import storeFactory from '@state/template'
import { netRequest } from '@api/client';
import { GENERIC_ERROR_MESSAGE } from '@utils/constants';

const apiResourceName = 'payments'
const modelName = 'payments'

const model = {
  id: null,
  ticket_id: null,
  payment_type: null,
  payment_method: null,
  annotation: null,
  amount: null,
  currency: null,
  payment_date: null,
  fees_actual: null,
  refund_actual: null,
  reversed: false,
  invoiced: false,
  praticx_exported: null,
  exported: 'new',
  exported_at: null,
  customer_id: null,
  old_amount: 0,
  country: null,
  ticket: {
    status: null,
    resolution: null,
    activity: {
      macro: null,
      category: null,
      subcategory: null
    }
  },
  placement_mp: {
    user_id: null,
    last_name: null,
    first_name: null
  },
  placement_fn: {
    kenan_id: null,
    last_name: null,
    first_name: null
  },
}

const filters = {
  ticket_id: null,
  payment_type: null,
  payment_method: null,
  annotation: null,
  amount: null,
  currency: null,
  payment_date: null,
  fees_actual: null,
  refund_actual: null,
  reversed: null,
  invoiced: null,
  praticx_exported: null,
  customer_id: null,
  created_at: null,
  managed_by: null,
  exported: null,
  category: null,
  macro: [],
  subcategory: []
}

const state = {
  payment_type_map: [
    { value: 1, text: 'Pagamento', type: '+' },
    { value: 2, text: 'Storno', type: '-' },
    { value: 3, text: 'Eccedenza', type: '+' },
    { value: 4, text: 'Storno Eccedenza', type: '-' },
    { value: 5, text: 'Non Riconciliabile', type: '+' },
    { value: 6, text: 'Storno Non Riconciliabile', type: '-' },
  ],
  bollatiSummary: {
    total_positive_entries: 0,
    total_negative_entries: 0,
  },
}

const getters = {
  paymentTypeView: (state) => {
    return state.payment_type_map.reduce((acc, obj) => {
      acc[obj.value] = obj.text;
      return acc;
    }, {});
  },
}

const actions = {
  async getBollatiSummary({ commit, state }, scopes = []) {
    try {
      commit('SET_LOADING')

      const data = await netRequest('POST', '/payments/search/', {
        filters: state.filters,
        scopes: [...scopes, 'bollatiSummary'],
        ctx: { flat: true, one: true },
      });

      commit('SET_BOLLATI_SUMMARY', data)
    } catch (err) {
      /* TO DO */
      /* Should implement error handling */
    } finally {
      commit('RESET_LOADING')
    }
  },
  async sendToPraticx({ commit, dispatch }) {
    try {
      commit('ui/SET_OVERLAY', true, { root: true });
      await netRequest('POST', '/payments/send_to_praticx/', {});
      dispatch('notifications/info', `Invio a Praticx avviato`, { root: true })
    } catch (err) {
      /* TO DO */
      /* Should implement error handling */
      dispatch('notifications/error', GENERIC_ERROR_MESSAGE, { root: true })
    } finally {
      commit('ui/SET_OVERLAY', false, { root: true });
    }
  }
}

const mutations = {
  SET_BOLLATI_SUMMARY(state, payload) {
    state.bollatiSummary = payload;
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  state,
  getters,
  actions,
  mutations,
})
