import { merge, cloneDeep } from 'lodash'
import { updateField } from 'vuex-map-fields'

export default ({ model, filters }) => ({
  SET_LOADING(state) {
    state.loading = true
    state.loadingCounter++
  },
  RESET_LOADING(state) {
    state.loadingCounter--
    if (!state.loadingCounter) state.loading = false
  },

  /** This is used for tables, stores a page at a time */
  SET_PAGINATED_ITEMS(state, { rows, totalRows, totalPages }) {
    state.pagination.rows = rows
    state.pagination.totalRows = totalRows
    state.pagination.totalPages = totalPages
  },
  RESET_PAGINATED_ITEMS(state) {
    state.pagination.rows = []
    state.pagination.totalRows = 0
    state.pagination.totalPages = 0
  },

  /** Set one field on the current at a time */
  SET_CURRENT_FIELDS(state, field) {
    updateField(state.current, field)
  },

  /** Set single field on the current by key value */
  SET_CURRENT_FIELD(state, field) {
    state.current[field.key] = field.value
  },

  /** set filters with one mutation **/
  SET_FILTERS(state, filters) {
    state.filters = merge({}, state.filters, filters)
  },
  RESET_FILTERS(state) {
    state.filters = {}
  },

  /** Set multiple or all fields on the current at a time */
  SET_CURRENT(state, values) {
    state.current = merge({}, state.current, values)
  },
  RESET_CURRENT(state) {
    state.current = cloneDeep(model)
  },

  /** Set one filter field at a time */
  SET_FILTER_FIELDS(state, field) {
    updateField(state.filters, field)
  },
  RESET_FILTER_FIELDS(state) {
    state.filters = cloneDeep(filters)
  },

  /** These are sequelize scopes */
  SET_SCOPES(state, scopes) {
    state.scopes = scopes
  },
  RESET_SCOPES(state) {
    state.scopes = []
  },

  /** This is used in every occasion when data is NOT paginated */
  SET_LIST(state, data) {
    state.list = data
  },
  RESET_LIST(state) {
    state.list = []
  },
  /** This is used in every occasion when data is NOT paginated */
  SET_STATS_DATA(state, data) {
    state.stats = data
  },
  RESET_STATS_DATA(state) {
    state.stats = null
  },
  SET_CTX(state, data) {
    state.ctx = data;
  },
  RESET_CTX(state) {
    state.ctx = {};
  },
})
