import {
  isEmpty,
  isArray,
  isString,
  overEvery,
  intersection,
  isBoolean,
} from 'lodash'
import storeFactory from '@state/template'

const apiResourceName = 'tickets/resolutions'
const modelName = 'esito'

const model = {
  active: true,
  categories: [],
  code: null,
  macros: [],
  show_in_app: false,
  status: null,
  success: true,
  system: false,
}

const filters = {
  active: null,
  categories: null,
  code: null,
  macros: null,
  status: null,
}
const By = {
  /** strictCode and code differ by the fact that an empty search
   * will return nothing in strict and everything in the other */
  strictCode: (code) => (resolution) => {
    let pass = false
    if (isString(code)) pass = code === resolution.code
    else if (isArray(code) && !isEmpty(code)) {
      pass = code.includes(resolution.code)
    }
    return pass
  },
  code: (code) => (resolution) => {
    let pass = true
    if (isString(code)) pass = code === resolution.code
    else if (isArray(code) && !isEmpty(code)) {
      pass = code.includes(resolution.code)
    }
    return pass
  },
  macro: (macro) => (resolution) => {
    let pass = true
    // Only check for resolutions that are bound, unrestricted ones will always be present
    if (resolution.macros.length) {
      if (isString(macro)) pass = resolution.macros.includes(macro)
      else if (isArray(macro) && !isEmpty(macro)) {
        pass = !!intersection(macro, resolution.macros).length
      }
    }

    return pass
  },
  category: (category) => (resolution) => {
    let pass = true
    // Only check for resolutions that are bound, unrestricted ones will always be present
    if (resolution.categories.length) {
      if (isString(category)) pass = resolution.categories.includes(category)
      else if (isArray(category) && !isEmpty(category)) {
        pass = !!intersection(category, resolution.categories).length
      }
    }
    return pass
  },
  status: (status) => (resolution) => {
    let pass = true
    // Only check for resolutions that are bound, unrestricted ones will always be present
    if (resolution.status) {
      if (isString(status)) pass = resolution.status === status
      else if (isArray(status) && !isEmpty(status)) {
        pass = status.includes(resolution.status)
      }
    }
    return pass
  },
  role: (role) => (resolution) => {
    let pass = true
    if(resolution.users_roles_permissions.length === 0) return false
    if (role) {
      pass = pass && resolution.users_roles_permissions.includes(role)
    }
    
    return pass
  },
  true: () => () => true,
}

const dropdownMapper = (resolution, rootState) => ({
  text: resolution.code,
  value: resolution.code,
  disabled: !resolution.active,
  system: resolution.system,
})

const getters = {
  /**
   * Passed an obj that hold props as filters it will return activities that comply with the filters.
   * Every prop must be either string or array to be used, otherwise it will be ignored.
   */
  byContext: (state, getters, rootState) => (context = {}) => {
    const { macro, category, code, status } = context
    const strict = isBoolean(context.strict) ? context.strict : false
    const checkRole = (context.checkRole && isBoolean(context.checkRole)) ? context.checkRole : false
    const role = rootState.auth.current.role.id;
    
    return state.list.filter(
      overEvery([
        By.macro(macro),
        By.category(category),
        By.status(status),
        (strict ? By.strictCode : By.code)(code),
        (checkRole ? By.role : By.true)(role),
      ])
    )
  },
  resolutionsByContext: (state, getters, rootState) => (context) =>
    getters.byContext(context).map(dropdownMapper),
}

const actions = {
  async preload({ dispatch }) {
    return dispatch('getDropdownList')
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  getters,
  actions,
})
