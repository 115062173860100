import storeFactory from '@state/template'

const apiResourceName = 'economic_detail'
const modelName = 'economicDetail'

const model = {
  id: null,
  code: null,
  creation_date: null,
  managed_by: null,
  update_type: null,
  residual_total_debt: null,
  oca_refund_expected: null,
  oca_fees_expected: null,
  payment_date: null,
  payment_method: null,
  amount: null,
  invoiced: null,
  payment: {
    id: null,
    payment_type: null,
    reversed: null,
    invoiced: null,
    exported: null,
    id_payment_reversed: null,
    country: null,
  },
}

const filters = {
  id: null,
  code: null,
  creation_date: null,
  managed_by: null,
  update_type: null,
  residual_total_debt: null,
  oca_refund_expected: null,
  oca_fees_expected: null,
  payment_date: null,
  payment_method: null,
  amount: null,
  invoiced: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
