import storeFactory from '@state/template'

const apiResourceName = 'bollato/movimenti'
const modelName = 'bollato movimento'

const model = {
  id: null,
  bollato_id: null,
  movimento_id: null,
}

const filters = {
  bollato_id: null,
  movimento_id: null,
  riconciliazione_id: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
