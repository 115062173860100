import { netRequest } from '@api/client'
import { omit } from 'lodash'
import storeFactory from '@state/template'

const apiResourceName = 'admin/users'
const modelName = 'utente'

const model = {
  auth_token: null,
  customer_id: null,
  customers_user_id: null,
  department: null,
  email: null,
  first_name: null,
  group_id: null,
  id: null,
  is_temp_password: null,
  last_access: null,
  last_ip_address: null,
  last_login: null,
  last_name: null,
  last_password_hashes: null,
  last_password_update: null,
  magic_link_token: null,
  mobile_phone_number: null,
  password: null,
  password_reset_expires: null,
  password_reset_token: null,
  password_to_send: null,
  projects: [],
  bound_customers: [],
  role_id: null,
  status: null,
  type: 'internal',
  username: null,
  work_phone_number: null,
}
const filters = {
  username: null,
  first_name: null,
  last_name: null,
  email: null,
  type: null,
  role_id: null,
  group_id: null,
  customer_id: null,
  dealer_id: null,
}

const state = {
  statuses: ['active', 'suspended', 'removed'],
  statuses_map_lang_it: {
    active: 'attivo',
    suspended: 'sospeso',
    removed: 'rimosso',
  },
  types: ['internal', 'external', 'system', 'ldap', 'google'],
}

const beforeUpdate = (state) =>
  omit(state.current, [
    'id',
    'auth_token',
    'password_reset_token',
    'password_reset_expires',
    'is_temp_password',
    'last_login',
    'last_access',
    'last_ip_address',
    'last_password_update',
    'last_password_hashes',
    'created_at',
    'updated_at',
    'deleted_at',
    'password',
  ])

const dropdownScopes = ['dropdown']
const sortDropdownBy = ['username']
const sortDropdownDesc = [false]

const mutations = {
  SET_CURRENT(state, values) {
    state.current = { ...state.current, ...values }

    if (state.current.projects && state.current.projects.length) {
      state.current.projects = state.current.projects.map((p) => p.key)
    }

    if (state.current.bound_customers && state.current.bound_customers.length) {
      state.current.bound_customers = state.current.bound_customers.map(
        (p) => p.id
      )
    }
  },
}

const getters = {
  usersByRole: (state) => (roleArray) => {
    return [...new Set(state.list.filter(itm => roleArray.includes(itm.role_id)).map((a) => a.username))]
  },
}

const actions = {
  async updatePassword({ commit, state, dispatch }, data) {
    const { id, password_to_send: password } = data || state.current
    let res
    try {
      commit('SET_LOADING')
      res = await netRequest('POST', `${apiResourceName}/${id}/change`, {
        id,
        password,
      })
      dispatch('notifications/info', `Password aggiornata.`, { root: true })
    } catch (err) {
      dispatch(
        'notifications/error',
        `Errore durante l'aggiornamento della password.`,
        { root: true }
      )
      console.warn('create error', err)
    } finally {
      commit('RESET_LOADING')
    }
    return res
  },

  async resetPassword({ commit, state, dispatch }) {
    let data
    try {
      commit('SET_LOADING')
      data = await netRequest(
        'POST',
        `${apiResourceName}/${state.current.id}/reset`,
        beforeUpdate(state)
      )
      dispatch('notifications/info', `Reset completato con successo`, {
        root: true,
      })
    } catch (err) {
      dispatch(
        'notifications/error',
        `Errore durante il reset della password.`,
        { root: true }
      )
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  state,
  beforeUpdate,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
  mutations,
  getters,
  actions,
})
