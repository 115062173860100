<script>
export default {
  components: {
    AppOverlay: () => import('@components/structure//app-overlay.vue'),
    AppBar: () => import('@components/structure/app-bar.vue'),
    NavDrawer: () => import('@components/structure/nav-drawer.vue'),
    Footer: () => import('@components/structure/footer.vue'),
  },
}
</script>

<template>
  <v-app>
    <NavDrawer v-if="$vuetify.breakpoint.mobile" />
    <AppBar />

    <v-main class="mb-10">
      <slot />
    </v-main>

    <Footer />
    <AppOverlay />
  </v-app>
</template>

<style>
#app {
  background-color: var(--v-background-base);
}
</style>
