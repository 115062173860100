import storeFactory from '@state/template'

const apiResourceName = 'exports'
const modelName = 'export'
const model = {
  id: null,
  type: null,
  status: null,
  filename: null,
  filesize: null,
  num_records: null,
  processed: null,
  error: null,
  export_by: null,
  export_started_at: null,
  export_ended_at: null,
}

const filters = {
  filename: null,
  export_ended_at: null,
  export_started_at: null,
  status: null,
  type: null,
  export_by: null,
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = []
const sortDropdownDesc = []

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
