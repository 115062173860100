import storeFactory from '@state/template'
import { netRequest } from '@api/client';
import { GENERIC_ERROR_MESSAGE } from '@utils/constants';

const apiResourceName = 'ebay/placement_debt_mp'
const modelName = 'PlacementDebtMp'

const model = {
  id_rif_nivi: null,
}

const filters = {
}

const actions = {
  async changeBadPhone({ commit, state, dispatch }, params) {
    try {
      commit('SET_LOADING')
      await netRequest('POST', `${apiResourceName}/change_bad_phone`, params);
      dispatch('notifications/success', 'La modifica è avvenuta con successo', { root: true });
    } catch (error) {
      dispatch('notifications/error', GENERIC_ERROR_MESSAGE, { root: true });
      console.warn('create error', error)
    } finally {
      commit('RESET_LOADING')
    }
  }
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  actions,
})
