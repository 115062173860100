<template>
  <v-app>
    <v-main>
      <v-row :class="{ 'fill-height': $vuetify.breakpoint.mdAndUp }">
        <v-col class="white d-flex align-center flex-column" cols="12" md="4">
          <div
            :class="{
              'mt-16': $vuetify.breakpoint.mdAndUp,
              'mt-8': !$vuetify.breakpoint.mdAndUp,
              ' mb-auto': true,
              'text-center': true,
            }"
          >
            <FrontpageLogo />
          </div>
          <LoginArt v-if="$vuetify.breakpoint.mdAndUp" />
          <div class="text-center">
            <!--
            <v-img
              src="@assets/ennova_logotype.svg"
              contain
              width="200"
              height="48"
              class="mt-4 mb-4"
            />
            -->
          </div>
        </v-col>
        <v-col
          class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"
        >
          <div
            class="ma-auto"
            :style="{
              width: $vuetify.breakpoint.mdAndUp ? '480px' : '96%',
              'max-width': $vuetify.breakpoint.mdAndUp ? '480px' : '96%',
            }"
          >
            <slot />
          </div>
          <v-subheader class="align-start font-weight-light">
            v{{ version }}
          </v-subheader>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import FrontpageLogo from '@components/common/FrontpageLogo.vue'
import { version } from '@/package.json'

export default {
  components: {
    FrontpageLogo,
    LoginArt: () => import('@components/icons/LoginArt.vue'),
  },

  data: () => ({
    version,
  }),

  created() {
    this.$vuetify.theme.dark = false

    // Prevent the install app from showing
    window.addEventListener('beforeinstallprompt', this.preventDefault)
  },

  beforeDestroy() {
    window.removeEventListener('beforeinstallprompt', this.preventDefault)
  },
  methods: {
    preventDefault(event) {
      event.preventDefault()
    },
  },
}
</script>

<style>
#app {
  background-color: var(--v-background-base);
}
</style>
