import storeFactory from '@state/template'
import { netRequest } from '@api/client.js'
import { fixedCurrency } from '@utils/formdata';

const apiResourceName = 'external_data'
const modelName = 'external_data'

const model = {
  externalData : null
}

const actions = {
  /**
   *
   * @param VuexActionContext
   * @returns {Promise<any>}
   */
  async readData({ commit, state, dispatch, rootState }) {
    // console.log(`PIPPO PLUTO PAP = ${JSON.stringify(rootState)}`)
    let data
    try {
      commit('SET_LOADING')
      data = await netRequest('POST', `${apiResourceName}`,
        {
          "macro" : rootState.ticketsActivities.current.macro,
          "category" : rootState.ticketsActivities.current.category,
          "external_id" : rootState.tickets.current.external_id,
        }
      )

      commit('SET_CURRENT', data)

    } catch (error) {
      dispatch('notifications/error', `${'Errore gestione External Data'}: ${error.message}`, { root: true })
      console.warn('create error', error)
      throw error
    } finally {
      commit('RESET_LOADING')
    }
  },

  async getAssociatedTickets({ commit, state, dispatch, rootState }) {
    let data
    try {
      data = await netRequest('POST', `${apiResourceName}/associated_tickets`,
        {
          "macro" : rootState.ticketsActivities.current.macro,
          "category" : rootState.ticketsActivities.current.category,
          "external_id" : rootState.tickets.current.external_id,
          "identificativo_ebay": state.current.identificativo_ebay,
        }
      )
      return data;
    } catch (error) {
      dispatch('notifications/error', `${'Errore gestione External Data'}: ${error.message}`, { root: true })
      console.warn('create error', error)
      throw error
    }
  },
}

const getters = {
  getTotalDebt(state) {
    return fixedCurrency(state.current.capitale_debito + state.current.spese_attese);
  }
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  actions,
  getters,
})
