import storeFactory from '@state/template'

const apiResourceName = 'admin/settings'
const modelName = 'configurazione'
const model = {
  key: null,
  value: null,
  type: null,
  ttl: null,
  section: null,
  description: null,
  default_value: null,
  order: null,
  updated_at: null,
}
const filters = {
  key: null,
  value: null,
  type: null,
}

const state = {
  types: ['string', 'integer', 'float', 'json', 'raw', 'boolean'],
}

const typesMap = {
  boolean: 'Booleano',
  float: 'Numero frazionario',
  integer: 'Numero intero',
  json: 'JSON',
  raw: 'Raw',
  string: 'Stringa di testo',
}
const getters = {
  typesOptions: (state) =>
    state.types.map((value) => ({
      text: typesMap[value],
      value,
    })),
}

const dropdownScopes = []
const sortDropdownBy = ['order']
const sortDropdownDesc = [false]

const getUpdateUrl = (state) => {
  return `${apiResourceName}/${state.current.key}`
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  state,
  getters,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
  getUpdateUrl,
})
