import { get } from 'lodash'
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import { parsePhoneNumber } from 'libphonenumber-js'

Vue.use(Vue2Filters, {
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: '.',
  },
  currency: {
    symbol: '€',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false,
  },
})

Vue.filter('duration', function(value = 0) {
  const date = new Date(0)
  date.setSeconds(value) // specify value for SECONDS here
  return date.toISOString().substr(11, 8)
})

Vue.filter('phoneNumber', function(value = 0) {
  const phoneNumber = parsePhoneNumber(value)
  return phoneNumber.formatInternational()
})

Vue.filter('get', get)
