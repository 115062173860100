const projects = Object.freeze({
  DEFAULT: 'NIVI',
  OTHER: 'OTHER',
})

export default {
  computed: {
    projects() {
      return projects
    },
    webenv() {
      const { hostname } = window.location

      if (hostname.includes('mykeyword')) return projects.OTHER

      return projects.DEFAULT
    },

    visualSettings() {
      const defaults = {
        loginBackground: require('@assets/images/background_nivi.jpg'),
        appbarColor: '#FFFFFF',
        appbarDark: false,
        footerVisible: true,
        footerColor: '#004691',
        footerDark: true,
        footerHeight: '150vh',
        frontPageTitle: '<strong>NIVI</strong> Platform',
        frontPageSubtitle: 'Powered by TxT',
        frontPageBoxTitle: 'Accedi',
        accent: 'primary',
        secondary: 'blue',
      }

      switch (this.webenv) {
        // use this to customize login page
        // case projects.OTHER:
        //   return {
        //     ...defaults,
        //     loginBackground: require('@assets/images/custom.jpg'),
        //     appbarColor: '#08080D',
        //     appbarDark: true,
        //     footerVisible: true,
        //     footerColor: '#58595b',
        //     footerDark: true,
        //     footerHeight: '120vh',
        //     frontPageTitle: 'DIGITAL SOLUTIONS',
        //     frontPageSubtitle:
        //       'Ottimizza la produttività del tuo business e migliora le relazioni commerciali con i tuoi clienti',
        //     frontPageBoxTitle: 'Knox Manage Plus',
        //     accent: 'orange darken-3',
        //     secondary: 'grey darken-2',
        //     displayCta: true,
        //   }

        default:
          return defaults
      }
    },
  },
}
