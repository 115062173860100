import storeFactory from '@state/template'

const apiResourceName = 'bollato'
const modelName = 'scarti'

const model = {
  riferimento: null,
  importo: null,
  intestatario: null,
  progressivo_bollato: null,
  cliente: null,
  data_bollato: null,
  riconciliato: null,
  movimenti: null,
  importi_cumulati: null,
  check_importo: null,
  check_nome: null,
  check_riferimento: null,
  differenza_importo: null,
  tipo_bollato: null,
  type: null,
  file: null,
  filesBollatoInAuto: null,
}

const filters = {
  not_riconciliato: 1,
  riconciliazione_id: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
