import storeFactory from '@state/template'
import { netRequest } from '@api/client';
import { GENERIC_ERROR_MESSAGE } from '@utils/constants';

const apiResourceName = 'economic_recovery_plan'
const modelName = 'EconomicRecoveryPlan'

const model = {
  id: null,
  code: null,
  creation_date: null,
  installment_amount: null,
  installment_number: null,
  payment_date_expected: null,
  payment_received: false,
  deleted: null,
}

const filters = {
  id: null,
  code: null,
  creation_date: null,
  installment_amount: null,
  installment_number: null,
  payment_date_expected: null,
  payment_received: null,
  deleted: null,
}

const actions = {
  async createPlan({ commit, dispatch }, params) {
    try {
      await netRequest('POST', `${apiResourceName}/create_plan`, params);
    } catch (err) {
      dispatch('notifications/error', GENERIC_ERROR_MESSAGE, { root: true });
      console.warn('create error', err)
      throw err;
    }
  }
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  actions,
})
