import storeFactory from '@state/template'

const apiResourceName = 'admin/sms_templates'
const modelName = 'SmsTemplates'

const model = {
  name: null,
  template: null,
  active: true,
  options: null,
}

const filters = {
  name: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
