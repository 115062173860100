import { netRequest } from '@/src/api/client'
import { omit } from 'lodash'
import storeFactory from '@state/template'
import * as Filesaver from 'file-saver'
import moment from 'moment'

const modelName = 'riconciliazione'
const apiResourceName = 'riconciliazione'

const model = {
  id: null,
  user_id: null,
}

const actions = {
  /**
   * Create a new resource using the state.current.
   * @param VuexActionContext
   * @returns {Promise<any>}
   */
  async createRiconciliazione({ commit, state, dispatch }) {
    let data
    try {
      commit('SET_LOADING')
      data = await netRequest('POST', `${apiResourceName}`, omit(state.current, ['id']))
      // dispatch('notifications/info', `${'Istanza Riconciliazione'}: ${error.message}`, { root: true }) // NO DISPATCH IN CREAZIONE
    } catch (error) {
      dispatch('notifications/error', `${'Istanza Riconciliazione'}: ${error.message}`, { root: true })
      console.warn('create error', error)
      throw error
    } finally {
      commit('RESET_LOADING')
    }
    return data
  },

  /**
   * @param VuexActionContext
   * @returns {Promise<any>}
   */
  async doReconciliation({ commit, state, dispatch }) {
    try {
      commit('SET_LOADING') 
      await netRequest(
        'PUT', 
        `${apiResourceName}/${state.current.id}`,
        {},
        { timeout: 10 * 60 * 1000 } // 10 min 
      ) 

      dispatch(
        'notifications/info',
        `Riconciliazione: Operazione terminata con successo.`,
        { root: true }
      )
    } catch (err) {
      dispatch('notifications/error', `Errore durante la riconciliazione.`, {
        root: true,
      })
      throw err
    } finally {
      commit('RESET_LOADING')
    }
  },

  async doExportReconciliation({ commit, state, dispatch }) {
    try {
      commit('SET_LOADING')

      dispatch(
        'notifications/info',
        `Riconciliazione: A breve verrà scaricato il file richiesto.`,
        { root: true }
      )
      
      const buffer = await netRequest(
        'GET',
        `${apiResourceName}/export/${state.current.id}`,
        {},
        {
          responseType: 'arraybuffer',
          timeout: 2 * 60 * 1000,
        }
      )

      const blob = new Blob([buffer])
      await Filesaver.saveAs(
        blob,
        `riconciliazione_${moment().format('DD-MM-YYYY_HH-MM-SS')}.xlsx`
      )
    } catch (error) {
      dispatch('notifications/error', `Errore durante il download del file.`, {
        root: true,
      })
    } finally {
      commit('RESET_LOADING')
    }
  },
}

export default storeFactory({
  modelName,
  model,
  apiResourceName,
  actions,
})
