import storeFactory from '@state/template'

const apiResourceName = 'customers'
const modelName = 'cliente'

const model = {
  address: null,
  address_2: null,
  alt_phone_number: null,
  alt_phone_number_2: null,
  cap: null,
  city: null,
  common_name: null,
  country: 'Italia',
  created_at: null,
  dealer_id: null,
  deleted_at: null,
  email: null,
  extra: {},
  file: null,
  first_name: null,
  last_name: null,
  lat: null,
  lng: null,
  mobile_phone_number: null,
  notes: '',
  notifications: {
    email: false,
    push: true,
    webhook: false,
  },
  piva_cfisc: null,
  province: null,
  region: null,
  type: null,
  updated_at: null,
  work_phone_number: null,
}
const filters = {
  common_name: null,
  piva_cfisc: null,
  type: null,
  city: null,
  cap: null,
  province: [],
  region: [],
}
const state = {
  types: ['business', 'enterprise', 'consumer'],
  countries: ['IT', 'FR', 'NL', 'BE', 'ES'],
}

const dropdownScopes = ['dropdown']
const sortDropdownBy = ['common_name']
const sortDropdownDesc = [false]

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
  state,
  dropdownScopes,
  sortDropdownBy,
  sortDropdownDesc,
})
