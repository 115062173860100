<template>
  <div>
    <div>
      <h1 class="text-h5 font-weight-thin secondary-text">
        Nivi Platform
      </h1>
      <h1 class="text-h3"><strong>TxT</strong></h1>
    </div>
  </div>
</template>

<script>
import utils from '@mixins/utils.js'
export default {
  name: 'FrontpageLogo',
  mixins: [utils],
}
</script>
