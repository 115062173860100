import { getField } from 'vuex-map-fields'

export default {
  getFiltersFields(state) {
    return getField(state.filters)
  },
  getCurrent(state) {
    return getField(state.current)
  },
  getListDropdown(state) {
    return state.list
  },
  getStats(state) {
    return state.stats ? state.stats[0] : {}
  },
}
