import storeFactory from '@state/template'

const apiResourceName = 'movimenti'
const modelName = 'movimenti'

const model = {
  descrizione_causale: null,
  descrizione_aggiuntiva_1: null,
  descrizione_aggiuntiva_2: null,
  descrizione_aggiuntiva_3: null,
  importo_avere: null,
  riferimento_cliente: null,
  ordinante: null,
  motivo_pagamento: null,
  conto_corrente: null,
  riconciliato: null,
  tipo_movimento: null,
  type: null,
  file: null,
}

const filters = {
  not_riconciliato: 1,
  riconciliazione_id: null,
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
