import storeFactory from '@state/template'
import { netRequest } from '@api/client.js'

const apiResourceName = 'bollatoMovimenti'
const modelName = 'bollatoMovimenti'

const model = {
  bollatoMovimenti : null
}

const actions = {
  async truncate({ commit, dispatch }) {
    // Pass undef so default params can trigger
    try {
      commit('SET_LOADING')
      await netRequest('DELETE', apiResourceName)
      commit('RESET_PAGINATED_ITEMS')

      dispatch('notifications/info', 'Bollato Reset', { root: true })
    } catch (err) {
      dispatch('notifications/error', 'Errore nel reset di Bollato', {
        root: true,
      })
      throw err
    } finally {
      commit('RESET_LOADING')
    }
  },
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  actions,
})
