import storeFactory from '@state/template'

const apiResourceName = 'ebay/recall_debt_records_fn'
const modelName = 'RecallDebtRecordsFn'

const model = {
  id: null,
  id_rif_nivi: null,
  scrub_rsn_id: null,
}

const filters = {
}

export default storeFactory({
  apiResourceName,
  modelName,
  model,
  filters,
})
